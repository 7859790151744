import React, { useEffect, useRef, useContext, useState } from 'react';

export enum FlowElements {
  TIME_PICKER = 'TIME_PICKER',
  SIDEBAR = 'SIDEBAR',
  BOTTOM_SECTION = 'BOTTOM_SECTION',
}

export const CalendarFlowContext = React.createContext<
  FlowElements | undefined
>(undefined);
export const CalendarFlowProvider = CalendarFlowContext.Provider;

const getScrollAmount = (element: HTMLElement | null | undefined) => {
  const rect = element?.getBoundingClientRect?.();
  if (rect) {
    const screenViewPort = {
      top: window.pageYOffset,
      bottom: window.pageYOffset + window.innerHeight,
    };
    const elementViewPort = {
      top: window.pageYOffset + rect.top,
      bottom: window.pageYOffset + rect.bottom,
    };
    const isElementInView =
      elementViewPort.top > screenViewPort.top &&
      elementViewPort.bottom < screenViewPort.bottom;
    if (!isElementInView) {
      const yOffset = -40;
      return elementViewPort.top - screenViewPort.top + yOffset;
    }
  }
  return 0;
};

const scrollIntoView = (element: HTMLElement | null | undefined) => {
  const scrollAmount = getScrollAmount(element);
  if (scrollAmount !== 0) {
    const y = window.pageYOffset + scrollAmount;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export function useFlow(flowElement: FlowElements) {
  const elementInFocus = useContext(CalendarFlowContext);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementInFocus === flowElement) {
      scrollIntoView(elementRef?.current);
    }
  }, [elementInFocus, flowElement]);

  return elementRef;
}

export function useStateFlow(flowElement: FlowElements) {
  const elementInFocus = useContext(CalendarFlowContext);
  const [element, setElement] = useState<HTMLDivElement>();

  useEffect(() => {
    if (elementInFocus === flowElement) {
      scrollIntoView(element);
    }
  }, [element, elementInFocus, flowElement]);

  return [element, setElement] as const;
}
